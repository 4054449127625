import React from "react";
import { AppLink } from "@base";
import { Text, Image, Container, ButtonAlt, Button } from "@atoms";
import { Carousel } from "@molecules";
import clsx from "clsx";

const CaseStudyCarousel = ({ heading, caseStudies, reverse }) => {
  return (
    <section className="relative my-10 overflow-hidden bg-purple/10 bg-text bg-cover pb-20 pt-12 sm:my-20 sm:pb-24 sm:pt-20">
      <Container padding>
        <Text
          variant="h3"
          className={clsx(
            "relative z-10 mb-6 w-full text-center text-slate md:mb-0 md:w-1/2 md:pl-6 md:text-left lg:pl-8",
            {
              // :reverse,
              "ml-auto": reverse,
            }
          )}
        >
          {heading}
        </Text>
        <div className="relative z-0">
          <Carousel maxVisible={1}>
            {caseStudies.map((c, i) => {
              return (
                <div className="relative h-full w-full md:px-8">
                  <AppLink to={c.url}>
                    <div className="flex flex-wrap items-stretch gap-12 md:-mx-5 md:gap-0 lg:-mx-8">
                      <div
                        className={clsx(
                          "relative z-0 order-2 -mt-8 w-full px-5 md:-mt-10 md:w-1/2 md:px-8",
                          { "md:order-1": reverse, "md:order-2": !reverse }
                        )}
                      >
                        <div className="relative h-full min-h-1/4-screen">
                          <Image
                            image={c.image}
                            fill
                            className="rounded-l-3xl rounded-br-6xl rounded-tr-3xl"
                            draggable={false}
                          />
                        </div>
                      </div>
                      <div
                        className={clsx(
                          "relative z-10 order-1 w-full px-5 md:mt-6 md:w-1/2 md:px-8",
                          {
                            "md:order-2": reverse,
                            "md:order-1": !reverse,
                          }
                        )}
                      >
                        <div className="flex h-full w-full flex-col space-y-4 rounded-xl bg-white p-6 shadow-xl">
                          <Text
                            variant="h5"
                            className="leading-[1.1] text-purple"
                          >
                            {c.title}
                          </Text>
                          <Text variant="sm">{c.metaDescription}</Text>
                          <ButtonAlt className="text-pink" color="pink">
                            Read Case Study
                          </ButtonAlt>
                        </div>
                      </div>
                    </div>
                  </AppLink>
                </div>
              );
            })}
          </Carousel>
        </div>

        <div className="mt-8 text-center">
          <Button to="/case-studies" color="purple" size="md">
            View All
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default CaseStudyCarousel;
